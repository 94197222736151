<template>
  <div>
    <v-row class="ma-0 pb-0 our-clients justify-center">
      <v-col sm="6" md="3" class="text-center">
        <a
          href="https://www.google.com/partners/agency?id=6023140230"
          target="_blank"
        >
          <picture>
            <source srcset="../../assets/PartnerBadge.webp" type="image/webp">
            <img
              src="../../assets/PartnerBadge.webp"
              style="object-fit: contain"
              width="200"
              height="200"
              alt="Google Partner"
              loading="lazy"
            />
          </picture>
        </a>
      </v-col>
    </v-row>
    <Footer bg-color="#b9b9b9" />
  </div>
</template>
<script>
export default {
  name: "OurPartners",
  components: {
    Footer: () => import("@/components/Footer"),
  },
};
</script>

<style scoped lang="scss">
.our-clients {
  color: #000000;
  padding: 0 0 40px 0;
}
</style>
